@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    background-color: #001731;
}

.about-img-container,
.about-img {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}